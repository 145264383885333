import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

const mapStateToProps = ({ loader }) => ({ isLoading: loader.isLoading });

const LoadingSpinner = (props) => {
  const { isLoading } = props;

  // modal-open class is not being auto-removed by Reactstrap when isLoading is false
  useEffect(() => {
    if (!isLoading) {
      document.querySelector('body').classList.remove('modal-open');
    };
  }, [isLoading])

  return (
    <Modal isOpen={isLoading} centered size="sm" backdrop={false}>
      <ModalBody className="loading-indicator">
        <Spinner color="info" />
        <span>Loading....</span>
      </ModalBody>
      <style global jsx>{`
        .loading-indicator {
          text-align: center
        }
        .loading-indicator span {
          margin-left: 10px
        }
      `}</style>
    </Modal>
  );
}

export default connect(mapStateToProps)(LoadingSpinner);
