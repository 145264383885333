import { useEffect } from 'react';
import Head from 'next/head';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingSpinner from '../features/loadingSpinner';

const Layout = props => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', 'UA-39406799-4', {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: window.document.title,
      })
    };
  }, []);

  return (
    <div>
      <Head>
        <link rel="stylesheet" href="/static/bootstrap.min.css" crossOrigin="anonymous"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-39406799-4"></script>
      </Head>
      {props.children}
      <LoadingSpinner/>
      <style global jsx>{`
      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      body {
        margin-top: 0;
        margin-bottom: 0;
        font-family: 'Lato', sans-serif;
      }
      .hidden {
        display: none;
      }
      @media (min-width: 768px) {
        .hideAboveMd {
          display: none;
        }
      }
      @media (max-width: 767px) {
        .hideBelowMd {
          display: none;
        }
      }
      `}</style>
    </div>
  );
}

export default Layout;
