const Logo = props => {
  const { routeToVenueList } = props;
  return (
    <div onClick={routeToVenueList} className="logo">
      <h1>Married There</h1>
      <style jsx>{`
      @font-face {
        font-family: 'Dancing Script';
        font-style: normal;
        font-weight: 400;
        src: local('Dancing Script Regular'), local('DancingScript-Regular'), url(https://fonts.gstatic.com/s/dancingscript/v11/If2RXTr6YS-zF4S-kcSWSVi_szLgiuEHiC4W.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      h1 {
        font-family: 'Dancing Script', cursive;
        color: #6bddcc;
        font-size: 2.5em;
        margin-bottom: 0;
      }
      p {
        color: lightgray;
      }
      .logo {
        cursor: pointer;
      }
      @media (max-width: 768px) {
        h1 {
          font-size: 2em;
        }
      }
      `}</style>
    </div>
  );
};

export default Logo;
